.App {
  text-align: center;
}
.App,
body,
html {
  height: 100%;
  min-height: 100vh;
}

.App-logo {
  width: 90vmin;
  @media screen and (min-width: 768px) {
    width: 65vmin;
  }
  pointer-events: none;
}

.checkmark {
  width: 24px;
}

.App-header {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  font-size: calc(10px + 2vmin);
  color: white;
  min-height: 15vh;
  max-height: 25vh;
  padding: 16px;
}
.sticker {
  display: flex;
  justify-content: center;
  margin-top: 4rem;
}
.App-hero {
  color: #ffbc0f;
}
.App-content {
  padding-top: 24px;
  background-color: #08244b;
  color: #fff;
  min-height: 75vh;
  border-top: 10px solid #00baed;
  font-weight: bold;
  overflow: hidden;
  position: relative;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}
.socials {
  display: flex;
  justify-content: center;
  gap: 24px;
}
.App-social {
  cursor: pointer;
  width: 24px;
}
.App-usp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
  flex-wrap: wrap;
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
}
.App-usp-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.usp-item {
  margin-right: 12px;
}

.vehicle {
  min-width: 0%;
  max-width: 200px;
  z-index: 5;
}
.image-container {
  display: flex;
  justify-content: center;
}
.square {
  position: absolute;
  background-color: #00baed;
}
.square1 {
  height: 20rem;
  width: 10rem;
  transform: rotate(135deg);
  bottom: -120px;
  left: -30px;
  z-index: 1;
}
.square2 {
  height: 10rem;
  width: 10rem;
  transform: rotate(135deg);
  bottom: -120px;
  right: -30px;
}
.square3 {
  height: 20rem;
  width: 10rem;
  transform: rotate(135deg);
  top: -120px;
  right: -30px;
}
.square4 {
  height: 20rem;
  width: 10rem;
  transform: rotate(135deg);
  top: -120px;
  left: -100px;
}
h1 {
  font-size: 2.8rem;
  z-index: 2;
  position: relative;
  text-shadow: -1.5px 0 black, 0 1.5px black, 1.5px 0 black, 0 -1.5px black;
}
p {
  font-size: 1.4rem;
  z-index: 2;
  position: relative;
}
.contact {
  font-size: 1.2rem;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 0.5rem;
}
